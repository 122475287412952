import React from 'react'
import { Link } from "gatsby"

import Layout3 from "../components/layout3"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"


import Footer from "../components/footer"

import ContactSection from "../components/home-page/contactSection"


import '../scss/style.scss'

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Breadcrumb from "react-bootstrap/Breadcrumb"

import Blog3Img from "../images/Mailsculpt-Blog-2.png"
import HarryImg from "../images/welcome_to_harrys.jpg"
import GrammarlyImg from "../images/grammarly-onboarding.jpg"
import InvisionImg from "../images/invision-app-newsletter.jpg"
import HarrySalesImg from "../images/Harrys-sales-email.jpg"
import EvernoteImg from "../images/Evernote-offer-upgrade.jpg"
import HubspotImg from "../images/hubspot-feedback.jpg"



const SixTypesOfEmailsYouMustSendYourSubscribers = () => (

    <Layout3 >

        <SEO
            title="6 Types of emails you must send your subscribers"
            description="Now that you have subscribers, it’s time to make a list of emails you will send to keep them engaged as well as grow your business. Check out this quick post about the 6 Types of emails you must send your subscribers (with examples & screenshots). "
            author="Mail Sculpt"
        />

        <Helmet>
            <meta property="og:url" content="https://www.mailsculpt.com/6-types-of-emails-you-must-send-your-subscribers/" />
            <meta property="og:image" content="https://www.mailsculpt.com/static/Mail-Sculpt-4-45f818c75373e0d86e013153c36b363f.jpg" />
        </Helmet>



        <Container style={{ marginTop: "120px" }}>

            <Row>
                <Col>
                    <Breadcrumb style={{ fontFamily: "Roboto", backgroundColor: "none" }}>
                        <Breadcrumb.Item><Link to="/" >Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/blogs" >Blogs</Link></Breadcrumb.Item>
                        {/* <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                Library
  </Breadcrumb.Item> */}
                        <Breadcrumb.Item active>6 Types of emails you must send your subscribers</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </Container>

        <Container style={{ maxWidth: "800px" }}>
            <Row>
                <Col>
                    <img src={Blog3Img} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p style={{ fontFamily: "Roboto" }}>28th August, 2020</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1 style={{ fontFamily: "Roboto" }}>6 Types of Emails You Must Send Your subscribers</h1>
                </Col>
            </Row>




            <Row>
                <Col>
                    <p style={{ fontFamily: "Roboto" }}>If you didn't already know, email marketing has a proven high ROI of 400%, its the best way to sell online, keep customers engaged, and nurture them over time. </p>
                    <p style={{ fontFamily: "Roboto" }}>But once you have a list, then where do you start? How do you ensure that this doesn't remain a list of email addresses but becomes your customer base &amp; fan base eventually? </p>
                    <p style={{ fontFamily: "Roboto" }}>An excellent copy, design, sophisticated automation, and all that jazz is okay, but it comes down to the type of emails you will send. </p>
                    <p style={{ fontFamily: "Roboto" }}>If you just send 'value' emails, your subscribers won't know you have products or services they can buy. And if you just send 'sales' emails, they won't get any value in advance to trust you or build an emotional connection. </p>
                    <p style={{ fontFamily: "Roboto" }}>So let's explore the 6 types of emails you must send your subscribers. </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Welcome Email</h2>
                    <p style={{ fontFamily: "Roboto" }}>A welcome email is like the first impression you make with someone. It will set the tone for further communication with the subscriber by familiarising them with your brand &amp; style of communication.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Here is an example of a welcome email from the eCommerce brand Harry's. It has all the right components a welcome email should have:
</p>
                    <ul>
                        <li style={{ fontFamily: "Roboto" }}>Starts with a short description of what's the brand promise</li>
                        <li style={{ fontFamily: "Roboto" }}>Reinforces the benefits of joining their email list with 'What you get' section </li>
                        <li style={{ fontFamily: "Roboto" }}>Gently asks to explore the products. </li>
                        <li style={{ fontFamily: "Roboto" }}>Social media links for further connection</li>
                    </ul>
                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <img src={HarryImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center" style={{ marginBottom: "40px" }}>
                <Col>
                    Source: <a href="https://www.harrys.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.harrys.com</a>
                </Col>
            </Row>



            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Onboarding</h2>
                    <p style={{ fontFamily: "Roboto" }}>If you have a Saas product, you should send a series of onboarding emails. The length can depend on how easy or complicated the product is.
</p>
                    <p style={{ fontFamily: "Roboto" }}>These emails help customers gain maximum value from the product and reach the 'aha' moment quickly, so use it more often and see them renew their subscription.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Here is an example from Grammarly that helps make premium uses most of their subscription:
</p>

                    <ul>
                        <li style={{ fontFamily: "Roboto" }}>It has three simple features highlighted with screenshots and two-line explanations.
</li>
                        <li style={{ fontFamily: "Roboto" }}>All CTAs lead to the premium account dashboard, where you can experiment with these features.
</li>
                        <li style={{ fontFamily: "Roboto" }}>The design is simple to navigate. There is a clear structure of heading, screenshot, and description.
</li>
                    </ul>
                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <img src={GrammarlyImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center" style={{ marginBottom: "40px" }}>
                <Col>
                    Source: <a href="https://www.grammarly.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.grammarly.com</a>
                </Col>
            </Row>



            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Newsletter</h2>
                    <p style={{ fontFamily: "Roboto" }}>Newsletters are essential to provide constant value to your audience. These emails are a way to share blog posts you publish, company updates, or new features introduced in your product. </p>
                    <p style={{ fontFamily: "Roboto" }}>You can share content that helps people stay on top of their game. It can be the best interviews, tips, or articles you find profound.
</p>
                    <p style={{ fontFamily: "Roboto" }}>These are generally broadcasted so you won't be segmenting your audience into categories. So make sure you include something for each kind of audience you have. Avoid leaning to only one type of content.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Don't forget to apply correct design techniques; ample white spaces and distinct CTAs will ensure your newsletter will be clear &amp; crips.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Here is an example of that from InVision App newsletter that has been curated by links from its blog &amp; other websites
</p>
                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <img src={InvisionImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center" style={{ marginBottom: "40px" }}>
                <Col>
                    Source: <a href="https://www.invisionapp.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.invisionapp.com</a>
                </Col>
            </Row>




            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Sales Email</h2>
                    <p style={{ fontFamily: "Roboto" }}>Once you have nurtured a relationship with your subscribers with valuable emails, newsletters, onboarding, etc., it's time to convert this into a transactional relationship.
</p>
                    <p style={{ fontFamily: "Roboto" }}>For this, you should send emails introducing your products or services. Please don't assume that the subscriber knows your website and will visit and buy by themselves.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Sales emails are required to remind them of the benefits of the products, lead them directly to the product page, so the experience is seamless.
</p>
                    <p style={{ fontFamily: "Roboto" }}>Here is an example from Harry's promoting its face lotion. That product is the focus of this whole email.
</p>
                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <img src={HarrySalesImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center" style={{ marginBottom: "40px" }}>
                <Col>
                    Source: <a href="https://www.harrys.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.harrys.com</a>
                </Col>
            </Row>


            <Row>
                <Col>
                    <p style={{ fontFamily: "Roboto" }}>If you notice, the email is engaging and naturally leads to the CTA: ' Shop face lotion.' This is because of an important design principle called the 'inverted pyramid' design.  </p>
                </Col>
            </Row>


            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Upgrade Email</h2>
                    <p style={{ fontFamily: "Roboto" }}>Similar to a sales email, you have upgrade emails in Saas products. If the subscriber is already using your free product service, it's highly likely that they are your target users. </p>
                    <p style={{ fontFamily: "Roboto" }}>You can convince them to upgrade by re-iterating the benefits or nudging with a discount. </p>
                    <p style={{ fontFamily: "Roboto" }}>Here is an example from Evernote. </p>
                </Col>
            </Row>

            <Row className="text-center">
                <Col>
                    <img src={EvernoteImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center" style={{ marginBottom: "40px" }}>
                <Col>
                    Source: <a href="https://evernote.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.evernote.com</a>
                </Col>
            </Row>


            <Row>
                <Col>
                    <ul>
                        <li style={{ fontFamily: "Roboto" }}>The 40% off is visible, and CTAs are geared towards an upgrade.
</li>
                        <li style={{ fontFamily: "Roboto" }}>The benefits of an upgrade are distinctly laid out with iconography.
</li>
                        <li style={{ fontFamily: "Roboto" }}>Every design element  - CTAs, discount image, icons - are in brand colors that helps build brand recall in the subscriber's mind.
</li>
                    </ul>
                    <p style={{ fontFamily: "Roboto" }}>If you wish to get such professional-looking email designs, checkout Mailsculpt. We specialize in responsive, high-converting, professional-looking emails at affordable costs.
</p>
                </Col>
            </Row>


            <Row>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>Feedback Email</h2>
                    <p style={{ fontFamily: "Roboto" }}>People appreciate brands that care about their opinion and put efforts into improving their products or services. Send emails to collect feedback when you deliver a product or when people have used your services for a month or some time. </p>
                    <p style={{ fontFamily: "Roboto" }}>Feedback emails will help you find about the areas that need improvement and things that customers like in your product or service. The response can also be used as testimonials to show social proof on your website. </p>
                    <p style={{ fontFamily: "Roboto" }}>It's an incredible way to grow your business. </p>
                    <p style={{ fontFamily: "Roboto" }}>Here is an example from Hubspot. The purpose is clearly laid out. It also incentivizes people to respond by offering them free passes. Notice that the color of CTA, text, etc. is on-brand, so you can't miss that this is from Hubspot.</p>

                </Col>
            </Row>


            <Row className="text-center">
                <Col>
                    <img src={HubspotImg} style={{ marginBottom: "0px", maxWidth: "500px" }} />
                </Col>
            </Row>
            <Row className="text-center" style={{ marginBottom: "40px" }}>
                <Col>
                    Source: <a href="https://www.hubspot.com/" style={{ fontFamily: "Roboto", textDecoration: "none", color: "rgba(0,0,0,0.87" }}>www.hubspot.com</a>
                </Col>
            </Row>








            <Row style={{ marginBottom: "50px" }}>
                <Col>
                    <h2 style={{ fontFamily: "Roboto", fontWeight: "bold" }}>What’s next?
</h2>
                    <p style={{ fontFamily: "Roboto" }}>You have your list of email addresses and your ready send; now, it's time to start designing the email framework.
</p>
                    <p style={{ fontFamily: "Roboto" }}>The content, copy, and images will keep changing, but once you have a framework ready, you will need to pick it, add your new content and send. You will not be wasting time starting from scratch every time.

</p>
                    <p style={{ fontFamily: "Roboto" }}>Creating a design framework will need you to know a bit of HTML ( even if you use the generic templates available), make your templates responsive, and take care of the aesthetics.

</p>
                    <p style={{ fontFamily: "Roboto" }}>With Mailsculpt, you can focus on your core business &amp; let the specialists take care of your emails. We specialize in responsive, high-converting, professional-looking emails. The process is affordable, fast, and result-oriented. Talk with one of our dedicated email specialists  <a href="javascript:$zopim.livechat.window.show();" style={{ fontWeight: "bold" }}>here</a>.
</p>
                </Col>
            </Row>


        </Container >

        <ContactSection />

        <Footer />




    </Layout3 >

)

export default SixTypesOfEmailsYouMustSendYourSubscribers